<template>
  <div>
    <div class="fill-height purple-gradient">
      <Navbar />
      <UserNavbar />
      <v-container class="px-5 px-sm-9">
        <SearchBar />
      </v-container>
    </div>
    <div class="body-container">
      <div
        v-if="reservation && !loading"
        :style="[
          $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
            ? { 'padding-bottom': '30px' }
            : { 'padding-bottom': '40px' },
        ]"
      >
        <v-container class="px-10 px-md-auto">
          <div class="text-h1 font-weight-black mb-4">
            Recibo #{{ reservation.id }}
          </div>
          <div class="d-flex flex-column flex-md-row ml-1">
            <div class="text-h4 mr-sm-10">
              Fecha de contratación:
              {{ dateFormatter(reservation.payment[0].date) }}
            </div>
          </div>
          <Button
            text="Volver"
            pill
            :height="44"
            outlined
            prepend-icon="fa-caret-left"
            class="mt-11"
            aria-label="Volver a lista de reservaciones"
            url="UserReservations"
          />
          <v-row class="mt-12">
            <v-col cols="12" md="6">
              <v-card
                elevation="0"
                class="mx-1 pa-7 text-h4 rounded-lg blue-border flex-grow-1"
                height="100%"
              >
                <div class="pb-1 text-h3 font-weight-black">
                  Información del cliente
                </div>
                <div class="mt-4">
                  {{ reservation.quotationRequest.requesterName }}
                </div>
                <div class="mt-4">
                  {{
                    reservation.quotationRequest.eventService.event.user
                      .preferedName
                  }}
                </div>
                <div class="mt-4">
                  {{ reservation.quotationRequest.requesterEmail }}
                </div>
                <div class="mt-4">
                  {{ reservation.quotationRequest.requesterPhone }}
                </div>
                <div class="mt-4">
                  {{ reservation.quotationRequest.requesterCity }},
                  {{ reservation.quotationRequest.requesterState }}
                </div>
              </v-card>
            </v-col>
            <v-col cols="12" md="6">
              <v-card
                elevation="0"
                class="mx-1 pa-7 text-h4 rounded-lg blue-border"
                height="100%"
              >
                <div class="pb-1 text-h3 font-weight-black">
                  Información del proveedor
                </div>
                <div class="mt-4">
                  <router-link
                    class="black--text text-decoration-underline"
                    :to="getProviderRoute"
                  >
                    {{
                      reservation.quotationRequest.eventService.service.provider
                        .name
                    }}
                  </router-link>
                </div>
                <div class="mt-4">
                  <router-link
                    class="black--text text-decoration-underline"
                    :to="getServiceRoute"
                  >
                    {{ reservation.quotationRequest.eventService.service.name }}
                  </router-link>
                </div>
                <div class="mt-4">
                  {{ providerAddress }}
                </div>

                <div class="mt-4">
                  {{
                    reservation.quotationRequest.eventService.service.provider
                      .phone
                  }}
                </div>
                <div class="mt-4">
                  {{
                    reservation.quotationRequest.eventService.service.provider
                      .email
                  }}
                </div>
              </v-card>
            </v-col>
          </v-row>
          <div class="my-14">
            <div class="mx-7 text-h4">
              <div class="mb-5 mb-md-0">
                <div class="text-h3 font-weight-black">
                  Información de reservación
                </div>
              </div>
              <v-row class="align-center mt-6" no-gutters>
                <v-col cols="12" sm="5" md="4">
                  <span>Ciudad del evento</span>
                </v-col>
                <v-col cols="12" sm="7" md="8">
                  <span>
                    {{ reservation.quotationRequest.eventCity }}
                  </span>
                </v-col>
              </v-row>
              <v-row class="align-center mt-5" no-gutters>
                <v-col cols="12" sm="5" md="4">
                  <span>Dirección</span>
                </v-col>
                <v-col cols="12" sm="7" md="8">
                  <span>
                    {{ reservation.quotationRequest.eventAddress }}
                  </span>
                </v-col>
              </v-row>
              <v-row class="align-center mt-5" no-gutters>
                <v-col cols="12" sm="5" md="4">
                  <span>Fecha del evento</span>
                </v-col>
                <v-col cols="12" sm="7" md="8">
                  <span>
                    {{
                      dateFormatterWithTime(
                        reservation.quotationRequest.eventDate
                      )
                    }}
                  </span>
                </v-col>
              </v-row>
              <v-row class="align-center mt-5" no-gutters>
                <v-col cols="12" sm="5" md="4">
                  <span>Tipo de evento</span>
                </v-col>
                <v-col cols="12" sm="7" md="8">
                  <span>
                    {{ reservation.quotationRequest.eventType }}
                  </span>
                </v-col>
              </v-row>
              <v-row class="align-center mt-5" no-gutters>
                <v-col cols="12" sm="5" md="4">
                  <span> Número de invitados</span>
                </v-col>
                <v-col cols="12" sm="7" md="8">
                  <span>
                    {{ reservation.quotationRequest.peopleQuantity }}
                  </span>
                </v-col>
              </v-row>
              <v-row class="align-center mt-5" no-gutters>
                <v-col cols="12" sm="5" md="4">
                  <span>Comentarios</span>
                </v-col>
                <v-col cols="12" sm="7" md="8">
                  <span class="text-pre-line">
                    {{
                      reservation.quotationRequest.clientComment
                        ? reservation.quotationRequest.clientComment
                        : "No se han proporcionado comentarios."
                    }}
                  </span>
                </v-col>
              </v-row>
              <div>
                <div class="mt-10 mb-5 mb-md-0">
                  <div class="text-h3 font-weight-black">
                    Desglose de servicio solicitado
                  </div>
                </div>
                <v-row class="align-center mt-6" no-gutters>
                  <v-col cols="12" sm="5" md="4">
                    <span>Tipo de servicio</span>
                  </v-col>
                  <v-col cols="12" sm="7" md="8">
                    <span>
                      {{
                        reservation.quotationRequest.eventService.service
                          .category
                      }}
                    </span>
                  </v-col>
                </v-row>
                <v-row class="align-center mt-4" no-gutters>
                  <v-col cols="12" sm="5" md="4">
                    <span>Costo de servicio (IVA incluido)</span>
                  </v-col>
                  <v-col cols="12" sm="7" md="8">
                    <span>
                      {{
                        numberToCurrency(
                          reservation.quotationRequest.paymentProvider
                        )
                      }}
                      MXN
                    </span>
                  </v-col>
                </v-row>
                <v-row class="align-center mt-4" no-gutters>
                  <v-col cols="12" sm="5" md="4">
                    <span>Comisión Zaturna (5%)</span>
                  </v-col>
                  <v-col cols="12" sm="7" md="8">
                    <span>
                      {{
                        numberToCurrency(
                          reservation.quotationRequest.paymentZaturna
                        )
                      }}
                      MXN
                    </span>
                  </v-col>
                </v-row>
                <v-row class="align-center font-weight-bold mt-4" no-gutters>
                  <v-col cols="12" sm="5" md="4">
                    <span>Total a pagar</span>
                  </v-col>
                  <v-col cols="12" sm="7" md="8">
                    <span>
                      {{
                        numberToCurrency(
                          reservation.quotationRequest.totalPayment
                        )
                      }}
                      MXN
                    </span>
                  </v-col>
                </v-row>
                <v-row class="align-center mt-4" no-gutters>
                  <v-col cols="12" sm="5" md="4">
                    <span> Cotización de servicio</span>
                  </v-col>
                  <v-col cols="12" sm="7" md="8">
                    <Button
                      text="Ver"
                      class="my-2 my-md-4"
                      aria-label="Descargar cotización de servicio"
                      is-external
                      download
                      :url="reservation.quotationRequest.quotationUrl"
                    />
                  </v-col>
                </v-row>
              </div>
              <div class="pb-14">
                <div class="mt-10 mb-md-8 mb-5 mb-md-0">
                  <div class="text-h3 font-weight-black">Plan de pagos</div>
                </div>
                <v-row
                  v-for="(pays, index) in reservation.payment"
                  :key="index"
                  no-gutters
                  class="my-4"
                >
                  <v-col cols="12" md="4">
                    <div>{{ getText(index) }}</div>
                  </v-col>
                  <v-col cols="12" md="3">
                    <div>{{ numberToCurrency(pays.total) }} MXN</div>
                  </v-col>
                  <v-col cols="12" md="5">
                    <div>{{ dateFormatter(pays.date) }}</div>
                  </v-col>
                </v-row>
                <v-row
                  v-if="reservation.status !== 'paid'"
                  no-gutters
                  class="my-2 my-sm-4"
                >
                  <v-col cols="12" md="4">
                    <div>Liquidación</div>
                  </v-col>
                  <v-col cols="12" md="3">
                    <div>
                      {{
                        numberToCurrency(
                          reservation.quotationRequest.totalPayment -
                            reservation.totalPaid
                        )
                      }}
                      MXN
                    </div>
                  </v-col>
                  <v-col cols="12" md="5">
                    <div
                      v-if="reservation.quotationRequest.paymentAdvance !== 100"
                      class="font-italic"
                    >
                      <span> Fecha límite: </span>
                      {{
                        dateFormatter(
                          reservation.quotationRequest.paymentDeadline
                        )
                      }}
                    </div>
                  </v-col>
                </v-row>
                <v-row no-gutters class="mt-4 pl-1">
                  <v-col cols="12" md="4">
                    <div>Total pagado</div>
                  </v-col>
                  <v-col cols="12" md="3">
                    <div>
                      {{ numberToCurrency(reservation.totalPaid) }}
                      MXN
                    </div>
                  </v-col>
                </v-row>
              </div>
            </div>
          </div>
          <!-- <v-row>
            <v-col sm="12">
              <v-row>
                <v-col cols="1"></v-col>
                <v-col cols="10" sm="10" md="8">
                  <template>
                    <h2>Contrato</h2>
                    <br />
                    <v-simple-table dense>
                      <template>
                        <tbody>
                          <tr>
                            <td class="py-2 px-0" width="200px">
                              Contrato proveedor
                            </td>
                            <td class="pl-sm-16">
                              <Button
                                text="Ver"
                                class="my-3"
                                aria-label="Descargar contrato de proveedor"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td class="pt-0 px-0" width="100px" color="descent">
                              <i
                                >Firmado
                                {{ dateFormater(paymentDetails[0].data) }}</i
                              >
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </template>
                </v-col>
                <v-col cols="0" sm="4"> </v-col>
              </v-row>
            </v-col>
          </v-row> -->
        </v-container>
      </div>
      <v-container v-else>
        <InlineLoading />
      </v-container>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/User/Shared/Navbar.vue";
import UserNavbar from "@/components/User/Shared/UserNavbar.vue";
import SearchBar from "@/components/Shared/SearchBar.vue";
import InlineLoading from "@/components/Shared/InlineLoading.vue";
import { mapState } from "vuex";
import { GET_RESERVATION_DETAILS } from "@/graphql/queries";
import { useQuery } from "@/graphql/index";
import { numberToCurrency } from "@/Utils/numberFormatter.js";
import {
  convertUnixToDDMMYYYY,
  unixToHourMinuteEdit,
  convertUnixToAmPm,
} from "@/Utils/dateConverter.js";
import { formatProviderAddress } from "../../Utils/Utils";
import Button from "@/components/Shared/Button.vue";

export default {
  name: "ReceiptDetails",
  components: { Navbar, UserNavbar, SearchBar, InlineLoading, Button },
  data: () => ({
    reservation: {
      payment: [],
      quotationRequest: {
        requesterName: "",
      },
      provider: {
        name: "",
      },
      service: {
        name: "",
      },
      user: {
        name: "",
      },
    },
    loading: true,
    reservationFetching: false,
    reservationFetched: false,
  }),
  computed: {
    ...mapState({
      user(state) {
        return state.user;
      },
    }),
    providerAddress() {
      const provider =
        this.reservation.quotationRequest.eventService.service.provider;
      return formatProviderAddress(provider);
    },
    getProviderRoute() {
      return {
        name: "UserProvider",
        params: {
          id: this.reservation.quotationRequest.eventService.service.provider
            .id,
        },
      };
    },
    getServiceRoute() {
      return {
        name: "UserService",
        params: {
          id: this.reservation.quotationRequest.eventService.service.id,
        },
      };
    },
  },
  watch: {
    user: {
      async handler(user) {
        if (!user.id) return;
        if (!this.reservationFetching && !this.reservationFetched) {
          this.reservationFetching = true;
          await this.fetchReservation();
          this.reservationFetched = true;
          this.reservationFetching = false;
        }
      },
      immediate: true,
    },
  },
  methods: {
    async fetchReservation() {
      const props = {
        id: parseInt(this.$route.params.id),
        clientId: parseInt(this.user.id),
        route: "user",
      };

      try {
        const { data, errors } = await useQuery(GET_RESERVATION_DETAILS, props);

        if (errors) throw errors;

        this.reservation = data.reservation;
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.$router.push({ name: "UserReservations" });
      }
    },
    getText(index) {
      return index === 0
        ? "Anticipo " + this.reservation.quotationRequest.paymentAdvance + "%"
        : index == this.reservation.payment.length - 1 &&
          this.reservation.status === "paid"
        ? "Liquidación "
        : "Pago " + index;
    },
    dateFormatter(unixTime) {
      return convertUnixToDDMMYYYY(unixTime);
    },
    dateFormatterWithTime(unixTime) {
      return (
        convertUnixToDDMMYYYY(unixTime) +
        " " +
        unixToHourMinuteEdit(unixTime) +
        " " +
        convertUnixToAmPm(unixTime)
      );
    },
    numberToCurrency(number) {
      return numberToCurrency(number);
    },
  },
};
</script>

<style scoped>
.v-data-table td {
  border-bottom: none !important;
}

tr:hover {
  background-color: transparent !important;
}

.v-data-table tr {
  vertical-align: 0;
}
.v-input--selection-controls {
  margin-top: 0;
  padding: 0;
}
.purple-gradient {
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0) 0%,
    rgba(98, 37, 130, 1) 100%
  );
  background-size: cover !important;
}
.blue-border {
  border: 1px solid var(--v-secondary-base);
}
</style>
